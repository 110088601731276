<template>
  <div :class="to ? 'pointer' : null" @click="click">
    <v-avatar v-if="user.img" :size="size">
      <image-cmp :img="user.img" cover/>
    </v-avatar>

    <v-avatar v-else :color="color" :size="size">
      <span>{{ initials }}</span>
    </v-avatar>
  </div>
</template>

<script setup>
import ImageCmp from './ImageCmp.vue'

const props = defineProps({
  user: Object,
  color: {
    type: String,
    default: 'primary'
  },
  size: {
    type: String,
    default: 'default'
  },
  to: String
})

const router = useRouter()

const initials = computed(() => !props.user.img ? (props.user.forename[0] + props.user.lastname[0]) : '')

function click() {
  if (props.to) {
    router.push(props.to)
  }
}
</script>