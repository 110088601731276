<template>
  <v-img
    :src="src"
    :srcset="srcset"
    :aspect-ratio="img.w / img.h"
    :cover="cover"
    @error="err = true"
  />
</template>

<script setup>
import { useStorage } from '../composables/storage.js'

const props = defineProps({
  img: Object,
  cover: Boolean
})

const { getImgSrc, getImgSrcSet, getFileURL } = useStorage()

const src = computed(() => props.img?.name ? (!err.value ? getImgSrc(props.img.name) : getFileURL(props.img.name)) : null)
const srcset = computed(() => props.img?.name && !err.value ? getImgSrcSet(props.img.name) : null)
const err = ref(false)
</script>