<template>
  <Teleport :disabled="!tabsShow" to="#appbarext">
    <v-tabs v-if="tabsShow" v-model="tab">
      <v-tab
        v-for="item in tabs" :key="item"
        :value="item"
      >
        {{ $t(item) }}
      </v-tab>
    </v-tabs>
  </Teleport>

  <v-window v-if="tabs" v-model="tab" :touch="false">
    <v-window-item
      v-for="item in tabs"
      :key="item"
      :value="item"
    >
      <component :is="components[item]"/>
    </v-window-item>
  </v-window>
</template>

<script setup>
const settings = inject('settings')

const components = {}
settings.features.forEach(feature => {
  const compName = feature.charAt(0).toUpperCase() + feature.slice(1) + 'View'
  components[feature] = defineAsyncComponent(() => import(`../components/${compName}.vue`))
})

const tabs = settings.features
const tabsShow = tabs.length > 1

const tab = ref(null)
</script>
