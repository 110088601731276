import { fs } from '@/firebase'
import { ref, uploadBytesResumable } from 'firebase/storage'
import SparkMD5 from 'spark-md5'

const FOLDER = 'contents'
const CACHE_CONTROL = 'public,max-age=31536000'
const IMG_SIZES = [640, 1280, 1920]
const IMG_TYPES = ['webp', 'png', 'jpg', 'jpeg', 'gif']

export function useStorage() {

  function getFileURL(fileName) {
    const host = fs._host.indexOf('localhost') > -1 && window.location.hostname !== 'localhost'
      ? fs._host.replace('localhost', window.location.hostname)
      : fs._host
    const pathURL = fs._protocol + '://' + host + '/v0/b/' + fs._bucket.bucket + '/o/' + FOLDER + '%2F'
    return pathURL + fileName + '?alt=media'
  }

  function getImgSrc(fileName) {
    const fileURL = getFileURL(fileName) // 'https://.../abc.jpg'
    const fileBase = fileURL.substring(0, fileURL.lastIndexOf('.')) // 'https://.../abc'
    const defaultSize = IMG_SIZES[1] // middle
    return fileBase + '_' + defaultSize + 'x' + defaultSize + '.webp?alt=media'
  }

  function getImgSrcSet(fileName) {
    const fileURL = getFileURL(fileName) // 'https://.../abc.jpg'
    const fileBase = fileURL.substring(0, fileURL.lastIndexOf('.')) // 'https://.../abc'
    return IMG_SIZES.map(w => fileBase + '_' + w + 'x' + w + '.webp?alt=media ' + w + 'w').join(', ')
  }

  async function uploadFile(file, progressCallback) {
    // TODO
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const fileText = await file.text()
      const fileHash = SparkMD5.hash(fileText)
      const fileType = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase()
      const fileName = fileHash + '.' + fileType
      const filePath = FOLDER + '/' + fileName

      const uploadTask = uploadBytesResumable(ref(fs, filePath), file, { cacheControl: CACHE_CONTROL })

      uploadTask.on('state_changed',
        function (snapshot) {
          if (progressCallback) {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            progressCallback(progress)
          }
        },
        function (error) {
          reject(error)
        },
        function () { // success
          const fileData = {
            name: fileName, // hash file name (with type but without path)
            type: fileType, // file type (.ext)
            desc: file.name // original file name
          }

          // for images, get width and height
          if (IMG_TYPES.includes(fileType)) {
            const fr = new FileReader
            fr.onload = function() {
              const img = new Image
              img.onload = function() {
                fileData.w = img.width
                fileData.h = img.height
                resolve(fileData)
              }
              img.src = fr.result
            }
            fr.readAsDataURL(file)
          } else {
            resolve(fileData)
          }
        }
      )
    })
  }

  return {
    getFileURL,
    getImgSrc,
    getImgSrcSet,
    uploadFile
  }
}